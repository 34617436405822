:root {
  --darkBlue : #11182B;
  --singhaYellow : #F8D02F;
  --singhaOrange : #D97706;
  --singhaButton : #FDC34D;
  --singhaButtonHover : #FBBF24;
  --errorText : #EF4444;
  --successText : #16A34A;
  --yellowButton : #FCD34D;
  --textSoft : #E5E5E5;
  --borderColor1 : #CBD5E1;
  --fontTH : "Prompt";
}

@font-face {
  font-family: "Prompt";
  src: url('./Assests/Fonts/TH/Prompt-Regular.ttf');
  src: url('./Assests/Fonts/TH/Prompt-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
 }
 
 @font-face {
  font-family: "Prompt";
  src: url('./Assests/Fonts/TH/Prompt-Medium.ttf');
  src: url('./Assests/Fonts/TH/Prompt-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
 }
 
 @font-face {
  font-family: "Prompt";
  src: url('./Assests/Fonts/TH/Prompt-SemiBold.ttf');
  src: url('./Assests/Fonts/TH/Prompt-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
 }

* {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
}

body {
  font-family: "Prompt";
  cursor: pointer;
}

a{
  font-family: 'Prompt';
  font-weight: 600 !important;
 }

 button , input , select , textarea {
  font-family: "Prompt";
 }

 /* Carousel Arrow */
 .carousel_product_next_arrow , .carousel_product_prev_arrow  {
    position: absolute;
    top: -77px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#001941;
    font-size: 1.2rem;
    width: 48px;
    height: 48px;
    padding: 10px 1px;
    background: #fff;
    box-shadow: -17.9875px 27.6668px 83px rgba(0, 0, 0, 0.07), -11.6586px 17.9322px 48.6088px rgba(0, 0, 0, 0.0531481), -6.92853px 10.6568px 26.437px rgba(0, 0, 0, 0.0425185), -3.5975px 5.53335px 13.4875px rgba(0, 0, 0, 0.035), -1.46565px 2.25433px 6.76296px rgba(0, 0, 0, 0.0274815), -0.333102px 0.512347px 3.2662px rgba(0, 0, 0, 0.0168519);
    border-radius: 4px;
    margin: 0 0 0 16px;
    cursor: pointer;
 }

 .carousel_product_next_arrow  {
    right: 0;
    z-index: 90;
 }

 .carousel_product_prev_arrow  {
    position: absolute;
    right: 60px;
    z-index: 90;
 }

 /* Landing Arrow Carousel */
 .landing_carousel_arrow_next , .landing_carousel_arrow_prev  {
    position: absolute;
    bottom: 10px;
    right: 44%;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#FFFFFF;;
    font-size: 1.2rem;
    width: 48px;
    height: 48px;
    padding: 10px 1px;
    background: #334155;
    box-shadow: -17.9875px 27.6668px 83px rgba(0, 0, 0, 0.07), -11.6586px 17.9322px 48.6088px rgba(0, 0, 0, 0.0531481), -6.92853px 10.6568px 26.437px rgba(0, 0, 0, 0.0425185), -3.5975px 5.53335px 13.4875px rgba(0, 0, 0, 0.035), -1.46565px 2.25433px 6.76296px rgba(0, 0, 0, 0.0274815), -0.333102px 0.512347px 3.2662px rgba(0, 0, 0, 0.0168519);
    border-radius: 4px;
    margin: 0 0 0 16px;
    cursor: pointer;
    z-index: 90;
}

.landing_carousel_arrow_prev {
    right: 47.5%;
}

/* Product detail arrow */
.carousel-product-detail-prev_arrow , .carousel-product-detail-next-arrow  {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color:#001941;
  font-size: 1.2rem;
  width: 48px;
  height: 48px;
  padding: 10px 1px;
  background: #fff;
  box-shadow: -17.9875px 27.6668px 83px rgba(0, 0, 0, 0.07), -11.6586px 17.9322px 48.6088px rgba(0, 0, 0, 0.0531481), -6.92853px 10.6568px 26.437px rgba(0, 0, 0, 0.0425185), -3.5975px 5.53335px 13.4875px rgba(0, 0, 0, 0.035), -1.46565px 2.25433px 6.76296px rgba(0, 0, 0, 0.0274815), -0.333102px 0.512347px 3.2662px rgba(0, 0, 0, 0.0168519);
  border-radius: 4px;
  margin: 0 0 0 16px;
  cursor: pointer;
  z-index: 98;
}

.carousel-product-detail-next-arrow {
  top: 40%;
  left: 40%;
}

.carousel-product-detail-prev_arrow   {
  top: 40%;
  left: 12%;
}
 

@media only screen and (min-width :320px) and (max-width:821px) {
  .landing_carousel_arrow_next , .landing_carousel_arrow_prev {
    width: 32px;
    height: 32px;
    font-size: 1em;
    top: 25%;
    right: 0;
  }

  .landing_carousel_arrow_prev {
    left: 0;
  }

  .carousel-product-detail-next-arrow {
    top: 20%;
    left: 90%;
  }

  .carousel-product-detail-prev_arrow   {
    top: 20%;
    left: 0;
  }

  /* Arrow Product Card  */
  .carousel_product_prev_arrow  {
    position: absolute;
    top: 35%;
    right: 0;
    left: -25px;
    width: 36px;
    height: 36px;
    border-radius: 4px;
  }

  .carousel_product_next_arrow  {
    top: 35%;
    right: 0;
    width: 36px;
    height: 36px;
    border-radius: 4px;
  }
}