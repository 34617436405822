.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 99;

    .loading-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 250px;
        height: 250px;
        border-radius: 50%;

        &::after {
            content: " ";
            position: absolute;
            display: block;
            width: 125px;
            height: 125px;
            border-radius: 50%;
            border: 6px solid var(--singhaYellow);
            border-color: var(--singhaYellow) transparent var(--singhaYellow) transparent;
            animation: lds-dual-ring 1.2s linear infinite;
        }

        @keyframes lds-dual-ring {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
        }
    }
}